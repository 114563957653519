
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'

export default defineComponent({
  name: 'RolesModalDelete',
  components: {
    TmFormLine,
    TmModal,
    TmButton,
  },
  props: {
    selected: {
      type: Array,
    },
  },
  setup(props) {
    const selectedRoles = ref(props.selected?.length)
    const totalUsers = ref((props.selected || []).reduce((acc, item: any) => acc + item.totalUsers, 0))
    const roleValue = ref('Supervisor')
    const roleList = ref([
      'Administrator',
      'Agent',
      'Supervisor',
      'Accountant',
    ])

    return {
      roleValue,
      roleList,
      selectedRoles,
      totalUsers,
    }
  },
})
